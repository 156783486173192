import { defineStore } from 'pinia'
// https://github.com/seatsio/seatsio-js
import { SeatsioClient } from '@/node_modules/seatsio/dist/src/SeatsioClient.js'
import { Region } from '@/node_modules/seatsio/dist/src/Region.js'
import { Category } from '@/node_modules/seatsio/dist/src/Charts/Category'
import { CreateEventParams } from '@/node_modules/seatsio/dist/src/Events/CreateEventParams'
import { UpdateEventParams } from '@/node_modules/seatsio/dist/src/Events/UpdateEventParams'

export function useSeatsioStore() {
  // Get all charts from workspace: Admin route
  const getAllCharts = async (secretWorkspaceKey: string) => {
    try {
      const seatsio = new SeatsioClient(Region.EU(), secretWorkspaceKey)
      const charts = await seatsio.charts.listAll()
      return charts
    } catch (e) {
      const error = e as Error
      console.error(error.message)
    }
  }

  // Create new event at seatsio. Admin route
  const create = async (
    title: string,
    secretWorkspaceKey: string,
    chartKey: string,
    pricing: [
      {
        key: string
        label: string
        color: string
        isGeneralAdmissionArea: boolean
      }
    ]
  ) => {
    try {
      const seatsio = new SeatsioClient(Region.EU(), secretWorkspaceKey)
      const categories = [] as Category[]
      pricing.forEach((item) => {
        if (item.isGeneralAdmissionArea === false) {
          // https://github.com/seatsio/seatsio-js/blob/e4bcd2e145a4757f239775bdc39547e08ea0675d/tests/events/createEvent.test.ts#L76
          categories.push(new Category(item.key, item.label, item.color, false))
        }
      })

      // const event = await client.events.create(chart.key, new CreateEventParams().withName('My event'))
      // https://github.com/seatsio/seatsio-js/blob/master/tests/events/createEvent.test.ts
      const event = seatsio.events.create(
        chartKey,
        new CreateEventParams().withCategories(categories).withName(title)
      )
      return event
    } catch (e) {
      const error = e as Error
      console.error(error.message)
    }
  }

  // Delete an event. Admin route
  const remove = async (secretWorkspaceKey: string, eventKey: string) => {
    try {
      const seatsio = new SeatsioClient(Region.EU(), secretWorkspaceKey)
      seatsio.events.delete(eventKey)
    } catch (e) {
      const error = e as Error
      console.error(error.message)
    }
  }

  // Update an event. Only catagories / pricing can be updated. Admin route
  const update = async (
    title: string,
    secretWorkspaceKey: string,
    eventKey: string,
    pricing: [{ key: string; label: string; color: string }]
  ) => {
    try {
      const seatsio = new SeatsioClient(Region.EU(), secretWorkspaceKey)
      const categories = [] as Category[]
      pricing.forEach((item) => {
        // https://github.com/seatsio/seatsio-js/blob/e4bcd2e145a4757f239775bdc39547e08ea0675d/tests/events/createEvent.test.ts#L76
        categories.push(new Category(item.key, item.label, item.color, false))
      })

      const eventUpdate = await seatsio.events.update(
        eventKey,
        new UpdateEventParams().withCategories(categories).withName(title)
      )
      return eventUpdate
    } catch (e) {
      const error = e as Error
      console.error(error.message)
    }
  }

  // Release must be sent to the server because we must use secret key
  const releaseSeatsFromSeatsio = async (
    eventId: string,
    seatId: string,
    holdToken: string
  ) => {
    try {
      await useFetchAuth(`/api/v1/front/event/release`, {
        method: 'POST',
        body: {
          eventId: eventId,
          seatId: seatId,
          holdToken: holdToken,
        },
      })
      return {
        success: true,
      }
    } catch (e) {
      return {
        success: true,
      }
    }
  }

  // Destroy holdToken. Must be executed on the server because of
  const destroyHoldToken = async (eventSlug: string, holdToken: string) => {
    try {
      await useFetchAuth(`/api/v1/front/event/destroy-hold-token`, {
        method: 'POST',
        body: {
          eventSlug: eventSlug,
          holdToken: holdToken,
        },
      })
      return {
        success: true,
      }
    } catch (e) {
      const error = e as Error
      console.error(error.message)
    }
  }

  // await client.events.book(<AN EVENT KEY>, ["A-1", "A-2"], <A HOLD TOKEN>)

  const book = async (cartItems: {}) => {
    try {
      const bookStatus = await useFetchAuth(`/api/v1/back/seatsio/book`, {
        method: 'POST',
        body: {
          cartItems: cartItems,
        },
      })
      return bookStatus
    } catch (e) {
      return e
    }
  }

  return {
    book,
    getAllCharts,
    create,
    remove,
    update,
    releaseSeatsFromSeatsio,
    destroyHoldToken,
  }
}
